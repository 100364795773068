import React, {useContext, useEffect} from 'react';

import PropTypes from 'prop-types';

import {Loader} from '@renofi/components-internal';
import {useGetUser} from '@renofi/graphql';
import {getUserId} from '@renofi/utilities/src/auth';
import logoutWithRedirect from '@renofi/utilities/src/logoutWithRedirect';
import {Context as StorageContext} from '@renofi/utilities/src/storage';

import Routes from '../Routes';

const Root = () => {
  const storage = useContext(StorageContext);
  const {getUser, loading, user} = useGetUser({lazy: true});

  useEffect(() => {
    (async () => {
      const userId = getUserId(storage);

      if (!userId) {
        storage.clear();
        return logoutWithRedirect();
      }

      await getUser();
    })();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return user && <Routes />;
};

Root.propTypes = {
  identityHash: PropTypes.string,
  config: PropTypes.object,
  user: PropTypes.object,
};

export default Root;
