import {identity} from 'ramda';

import {compose, mapProps} from '@renofi/recompose';

import {GET_PROJECT_RENOVATION_UPDATES} from '../queries';

import withQuery from './withQuery';

export default ({mapper = identity, options} = {}) =>
  compose(
    withQuery({
      query: GET_PROJECT_RENOVATION_UPDATES,
      props: ({data: {projectRenovationUpdatesV2}, ...props}) => ({
        renovationUpdates: projectRenovationUpdatesV2,
        ...props,
      }),
      options:
        options ||
        (({id, size = 'medium', projectId}) => ({
          variables: {id: projectId || id, size},
        })),
    }),
    mapProps(mapper),
  );
