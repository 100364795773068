import {pathOr} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose';

import {REJECT_EXCEPTION_REQUEST} from '../mutations';
import {GET_EXCEPTION_REQUEST} from '../queries';

import withApollo from './withApollo';
import withMutation from './withMutation';

export default () =>
  compose(
    withApollo,
    withMutation(REJECT_EXCEPTION_REQUEST, {
      name: 'denyExceptionRequest',
    }),
    withHandlers({
      rejectExceptionRequest:
        ({client, denyExceptionRequest}) =>
        async (id, message) => {
          const response = await denyExceptionRequest({
            variables: {id, message},
          });
          const exceptionRequest = pathOr(
            null,
            ['data', 'denyExceptionRequest', 'exceptionRequest'],
            response,
          );
          if (exceptionRequest) {
            const {exceptionRequestById} = client.readQuery({
              query: GET_EXCEPTION_REQUEST,
              variables: {id},
            });
            if (exceptionRequestById) {
              client.writeQuery({
                query: GET_EXCEPTION_REQUEST,
                variables: {id},
                data: {
                  exceptionRequestById: exceptionRequest,
                },
              });
            }
          }
          return response;
        },
    }),
  );
