import {pathOr} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose';

import {CANCEL_UNDERWRITING_REQUEST} from '../mutations';
import {GET_UNDERWRITING_REQUESTS} from '../queries';

import withApollo from './withApollo';
import withMutation from './withMutation';

export default () =>
  compose(
    withApollo,
    withMutation(CANCEL_UNDERWRITING_REQUEST, {
      name: 'cancelRenovationUnderwritingRequest',
      options: {
        refetchQueries: [
          {query: GET_UNDERWRITING_REQUESTS, variables: {status: 'active'}},
        ],
      },
    }),
    withHandlers({
      cancelUnderwritingRequest:
        ({cancelRenovationUnderwritingRequest}) =>
        async (id) => {
          const response = await cancelRenovationUnderwritingRequest({
            variables: {id},
          });
          const success = pathOr(
            false,
            ['data', 'cancelRenovationUnderwritingRequest', 'success'],
            response,
          );
          return success;
        },
    }),
  );
