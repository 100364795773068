import {identity, propOr} from 'ramda';

import {compose, mapProps, withProps} from '@renofi/recompose';
import omitProps from '@renofi/utilities/src/omitProps';

import withRenovationReadyFile from './withRenovationReadyFile';

export default ({mapper = identity} = {}) =>
  compose(
    withRenovationReadyFile(),
    withProps(({renovationReadyFile}) => ({
      documents: propOr([], 'taskDocuments')(renovationReadyFile),
      renofiProvidedDocuments: propOr(
        [],
        'renofiProvidedDocuments',
      )(renovationReadyFile),
    })),
    omitProps(['renovationReadyFile']),
    mapProps(mapper),
  );
