import {identity, isNil, path, pipe, prop} from 'ramda';

import {
  branch,
  compose,
  mapProps,
  renderNothing,
  withProps,
} from '@renofi/recompose';

import withLenderById from './withLenderById';
import withUser from './withUser';

export default ({mapper = identity, options = {}} = {}) =>
  compose(
    branch(
      pipe(prop('lenderId'), isNil),
      compose(
        withUser(),
        branch(pipe(path(['user', 'lenderId']), isNil), renderNothing),
        withProps(({user}) => ({
          lenderId: user?.lenderId,
        })),
      ),
    ),
    withLenderById({
      options,
    }),
    mapProps(mapper),
  );
